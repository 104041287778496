body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #28C8BE;
}

h2 {
  padding: 20px 15px 20px 0;
  border-bottom: 3px solid #000;
  margin-bottom: 25px;
}

h3 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.hidden {
  display: none;
}

.btn-primary {
  background-color: #0a234d;
  border-color: #0a234d;
}

.btn-primary:hover {
  background-color: #28C8BE;
  border-color: #28C8BE;
}

button.ico-button span {
  display: inline-block;
  margin-left: 5px;
  -webkit-transform: translate(0,-1px);
  -moz-transform: translate(0,-1px);
  transform: translate(0,-1px);
}
.flexBottoniera {
  display: flex;
  width: 100%;
  justify-content: end;
}
.flexBottoniera button {
  margin-left: 10px;
  width: auto;
}
.card-header .flexBottoniera button {
  width: auto;
}
button.ico-button.fixed-width {
  width: 38px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
button.ico-button.fixed-width span {
  margin-left: 0;
}

button.table-button {
  background: none;
  border: 0;
  font-size: 20px;
}

button.table-button.trash {
  color: #FE0000;
}

.actionBar {
  margin-bottom: 20px;
}
.actionBar button {
  width: 100%;
}
.bottoniera {
  text-align: center;
  padding: 35px 0;
}

.bottoniera .row {
  justify-content: center;
}

.errors {
  color: #FE0000;
  font-weight: bold;
  margin-bottom: 35px;
}

.tac {
  text-align: center;
}
.tar {
  text-align: right;
}
.tal {
  text-align: left;
}
.dateInput {
  width: 100%;
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
input[type="checkbox"] {
  margin-right: 10px;
  display: inline-block;
  -webkit-transform: translate(0,1px);
  -moz-transform: translate(0,1px);
  transform: translate(0,1px);
}
.card-header .form-group {
  margin-bottom: 0;
}
input.mbl-input {
  border-radius: 4px;
  height: 38px;
  line-height: 38px;
  padding: 0 10px;
}
body>iframe {
  display: none !important;
}
.fa.error {
  color: #FE0000;
}