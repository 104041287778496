aside#sidebar.main-sidebar {
    height: 100vh;
}

.brand-link .brand-image {
    margin-left: 0;
}

#sidebar, #sidebar .sidebar, #sidebar .brand-link {
    background: #000;
}

#sidebar li.nav-item{
    line-height: 40px;
}

#sidebar li.nav-item a {
    display: block;
    -webkit-transition-duration: .4s;
    -moz-transition-duration: .4s;
    transition-duration: .4s;
}

#sidebar li.nav-item a .nav-icon {
    margin: 0 17px;
}

#sidebar li.nav-item a:hover {
    color: #000;
    background: #c2c7d0;
}

#sidebar li.nav-item p {
    display: inline;
}

#sidebar .nav-link {
    padding: 0;
}
#sidebar .nav-treeview {
    display: none;
    padding-left: 0;
    transition-duration: .6s;
}
#sidebar:hover .nav-treeview {
    display: block;
    padding-left: 15px;
}
.nav-sidebar:hover .dashboard .nav-link>.right, 
.nav-sidebar:hover .dashboard .nav-link>p>.right {
    position: absolute;
    right: auto;
    left: 10px;
    top: 11px;
}
.nav-sidebar .dashboard .nav-link>.right, 
.nav-sidebar .dashboard .nav-link>p>.right {
    position: absolute;
    right: auto;
    left: 10px;
    top: 11px;
    transition: .4s;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    line-height: 15px;
    padding: 0;
}