.main-header {
    justify-content: space-between;
}

#logout {
    color: #28C8BE;
    border: 0;
    background: none;
    outline: 0;
}
.langChooser {
    display: flex;
    margin-left: 10px;
}
.langChooser .lang {
    outline: 0;
    padding: 0;
    border: 0;
    background: none;
    margin-right: 5px;
    opacity: .5;
}
.langChooser .lang.selected {
    opacity: 1;
}
.langChooser .lang img {
    width: 15px;
}
.navElements {
    display: flex;
    width: 700px;
    justify-content: space-between;
}