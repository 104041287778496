#login {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #000;
    overflow: auto;
}

#logo-login {
    margin: 100px auto 30px;
    display: table;
}

#login h2 {
    margin-bottom: 30px;
}

#login .login-box {
    background: #FFF;
    padding: 15px;
    margin: 0 auto 100px;
}

.forgot {
    text-align: right;
    line-height: 38px;
    text-decoration: underline;
    font-size: 12px;
    display: block;
}