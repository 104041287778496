#profileMenu {
    position: relative;
    margin-right: 15px;
}
#profileMenu #avatar {
    background: #0a234d;
    color: #FFF;
    text-align: center;
    height: 44px;
    width: 44px;
    line-height: 44px;
    font-weight: bold;
    border-radius: 50px;
}
#profileMenu #services {
    max-height: 0;
    overflow: hidden;
    transition-duration: .4s;
    position: absolute;
    background: #FFF;
    right: 0;
}
#profileMenu:hover #services {
    max-height: 200px;
    
}
#profileMenu #services .servicesContainer {
    padding: 5px 15px;
    position: relative;
    border: 1px solid #0a234d;
    border-radius: 5px;
    margin-top: 5px;
    min-width: 250px;
}
#profileMenu #services .servicesContainer .userService {
    border-bottom: 1px solid #0a234d;
    padding: 5px 0;
}
#profileMenu #services .servicesContainer .userService:last-child {
    border: 0;
    color: #FE0000;
    cursor: pointer;
}
#profileMenu #services .servicesContainer .arrow-up {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #0a234d;
    top: -5px;
    position: absolute;
    right: 15px;
}